import { FC, useMemo } from "react";
import { styled } from "@mui/material";
import imgFlag from "./unnamed.png";
import { isDarkMode } from "utills";

interface CustomeDataProps {
    data: any,
    onhandleChange: (step) => void;
}
const CustomeLine: FC<CustomeDataProps> = ({ data, onhandleChange }) => {
    
    const fillUpto = useMemo(() => {
        // return (data.filter(x => x.isCurrentWeek).length || 1) - 1
        const fill = data.findIndex(x => x?.isBrokenWeek);
        return fill == -1 ? 0 : fill
    }, [data])

    if (!data) return null;
    return (
        <Streak>
            <Line>
                <FillLine fillUpto={fillUpto} />
            </Line>
            {data?.map((w, i, arr) => {
                const isLastWeekBroken = i + 1 === arr.length && w?.isBrokenWeek
                return (
                    <Circle data-week={w.weekDisplayName} key={w.weekDisplayName} onClick={() => (w.hasCompleted || w.isCurrentWeek) ? onhandleChange(i) : null} completed={Boolean(w?.hasCompleted)} missed={Boolean(w?.isBrokenWeek)} isLastWeekBroken={isLastWeekBroken}>
                       {i + 1 === arr.length ? <Flag src={imgFlag} /> : null}
                        {w?.hasCompleted ? <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.85029 0.803395L2.53124 3.86205C2.43167 3.95382 2.30096 4 2.17025 4C2.03954 4 1.90883 3.95382 1.80926 3.86205L0.149782 2.33272C-0.0499273 2.14876 -0.0499273 1.85132 0.149782 1.66736C0.349398 1.48332 0.672056 1.48332 0.871765 1.66736L2.17025 2.86401L5.1283 0.138035C5.32792 -0.0460117 5.65058 -0.0460117 5.85029 0.138035C6.0499 0.321996 6.0499 0.619348 5.85029 0.803395Z" fill="#FAFAFA" />
                        </svg> : w?.isCurrentWeek ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                            : w?.isBrokenWeek ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                : null}
                        
                    </Circle>
                )
            })}

        </Streak>
    )
}

export default CustomeLine;

const Flag = styled("img")({
    position: 'absolute',
    bottom: 7,
    left: -1,
    height: 36,
    zIndex:-1
})

const Streak = styled("div")({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: "relative",
    margin: "5px 30px 40px 15px"
})

const Line = styled("div")({
    backgroundColor: "#c9dcf3",
    position: "absolute",
    left: 10,
    right: 10,
    height: 2,
    zIndex: 1
})

const FillLine = styled("div")<{ fillUpto: number }>(({ fillUpto }) => ({
    height: 2,
    backgroundColor: "rgba(67, 191, 111, 1)",
    width: `calc(${fillUpto ?? 0} * 14.29%)`
}))

const Circle = styled("div")<{ completed?: boolean, missed?: boolean, isLastWeekBroken: boolean }>(({ completed, missed, theme, isLastWeekBroken }) => ({
    ...{
        height: 18,
        width: 18,
        borderRadius: "50%",
        backgroundColor: completed ? "rgba(67, 191, 111, 1)" : missed ? "#ed3b3b" : "#c9dcf3",
        borderColor: "#f6faff",
        borderWidth: "2px",
        borderStyle: "solid",
        position: 'relative',
        zIndex: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "&::after": {
            content: 'attr(data-week)',
            position: 'absolute',
            top: '22px',
            fontSize: '10px',
            color: isDarkMode(theme) ? "#fff" : '#606060',
        }
    }, ...(isLastWeekBroken ? {} : {
        "&:last-child": {
            backgroundColor: isDarkMode(theme) ? "#131722" : '#f6faff',
            borderColor: isDarkMode(theme) ? "#131722" : '#f6faff',
        }
    })
}))

