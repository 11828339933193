import { jwtDecode, JwtPayload } from "jwt-decode";
import { Theme } from "@mui/material";
import { Maybe } from "api";
export { default as reactStringReplace } from "./reactStringReplace";
export * from "./time-ago";
export { getReadableText } from "./getReadableText";
/**
 * Method for generate path for tab active
 */
export const extractPathname = (pathname: string): string => {
  const pathnameArr = pathname.split("/");
  if (pathnameArr.length > 3) {
    pathnameArr.pop();
    return pathnameArr.join("/");
  }
  return pathname;
};

/**
 * Method for Image Path
 */

export const imagePath = (data: {
  mainUri: string;
  value: string;
}): string | undefined => {
  if (data?.value) {
    if (new RegExp(/https:/).test(data.value)) {
      return data.value;
    }
    return data.mainUri + data.value;
  }
  return undefined;
};


export const isImage = (file: File): boolean => {
  const ext = file.name.split(".").pop() as string;
  if (["png", "jpg", "jpeg", "gif"].includes(ext)) return true;
  return false;
};

export const isDoc = (file: File): boolean => {
  const ext = file.name.split(".").pop() as string;
  if (["pdf"].includes(ext)) return true;
  return false;
};

export const manageAnyHashtag = (text: string): string => {
  const exp = /(?:^|\s)(#[a-zA-Z0-9]\w*)/g;
  const matches = text.match(exp) || [];
  matches.forEach((item) => {
    item = item.replace(/\s/g, "");
    const re1 = new RegExp(`^${item}`, "gi");
    const re2 = new RegExp(`[^[]${item}`, "gi");
    text = text.replace(re1, ` [${item.toLocaleLowerCase()}:${item.slice(1)}]`);
    text = text.replace(re2, ` [${item.toLocaleLowerCase()}:${item.slice(1)}]`);
  });
  return text;
};

export const isJwtExpired = (token: string) => {
  if (typeof token !== "string" || !token)
    throw new Error("Invalid token provided");

  let isJwtExpired = false;
  const { exp } = jwtDecode<JwtPayload>(token);
  const currentTime = new Date().getTime() / 1000;

  if (exp && currentTime > exp) isJwtExpired = true;

  return isJwtExpired;
};

export const isDarkMode = (theme: Theme) => theme.palette.mode === "dark";

export const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1) as any) + "k"
    : Math.sign(num) * Math.abs(num);
};

export const getIsRingVisible = (x: Maybe<number> | undefined) => x === 300;
