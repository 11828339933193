import { styled, Typography } from "@mui/material";
import { Club, DollarTag, Hashtag, User } from "api";
import { FC, useMemo } from "react";

interface CombinationSearchSubTitleProps {
  data: (User | DollarTag | Hashtag | Club | null | undefined)[];
}

export const CombinationSearchSubTitle: FC<CombinationSearchSubTitleProps> = ({
  data,
}) => {
  const renderSubTitle = useMemo(() => {
    const [first, ...rest] = data.filter(Boolean);

    if (data?.filter(Boolean)?.length === 1) {
      if (first?.__typename === "User") {
        return <SmallText>in People</SmallText>;
      }
      if (first?.__typename === "Club") {
        return <SmallText>in Clubs</SmallText>;
      }
      if (first?.__typename === "DollarTag") {
        return (
          <SmallText>in {first?.dollarTagType}</SmallText>
        );
      }
      if (first?.__typename === "Hashtag") {
        return <SmallText>in Topics</SmallText>;
      }
      return null;
    }

    return rest
      ?.map((d: any) => {
        if (d?.__typename === "DollarTag") {
          return [
            <SmallText key="on">
              on
            </SmallText>,
            <SmallText
              key={d.dollarTagName}
              sx={{ color: "#1b6ac9"}}
            >
              ${d.dollarTagName}
            </SmallText>,
          ];
        }
        if (d?.__typename === "Hashtag") {
          return [
            <SmallText key="with">
              with
            </SmallText>,
            <SmallText
              key={d.hashtagName}
              sx={{ color: "#1b6ac9" }}
            >
              #{d.hashtagName}
            </SmallText>,
          ];
        }
        if (d?.__typename === "Club") {
          return [
            <SmallText key="in">
              in
            </SmallText>,
            <SmallText
              key={d.clubName}
              sx={{ color: "#1b6ac9" }}
            >
              {d.clubName}
            </SmallText>,
          ];
        }
        return [];
      })
      .flatMap((x) => x);
  }, [data]);

  return <Typography sx={{ fontSize: "12px", lineHeight: "14px" }}>{renderSubTitle}</Typography>;
};

const SmallText = styled("span")({
  marginRight: 4,
});
