if (!("fetch" in window)) {
  import("whatwg-fetch");
}

if (!("IntersectionObserver" in window)) {
  import("intersection-observer");
}

if (!('scrollRestoration' in history)) {
  import('delayed-scroll-restoration-polyfill');
}