import React, { ReactNode, useCallback, useState } from "react";
import {
  Fab,
  IconButton,
  Zoom,
  styled,
  ClickAwayListener,
} from "@mui/material";

import { useCreateAndSharePostContext } from "./context";
import { ActionTypes } from "./types";
import { COLORS } from "assets/colors";
import { PlusCircle32Icon } from "icons";
import {
  ClubPermissionsBasedOnMemberType,
  PermissionsInAnyClub,
  PlatformPermissionFromFeatureTypes,
  useGetFeatureToPlatformPermissionQuery,
  useGetPostPermissionsInAnyClubQuery,
} from "api";
import { useLocation } from "react-router-dom";
import { useClubPermission } from "hooks";

import ShareAnInsightIcon from "assets/icons/svg/share-an-insight.svg";
import AskAQueryIcon from "assets/icons/svg/ask-a-query.svg";
import ShareAStockIdeaIcon from "assets/icons/svg/share-a-stock-idea.svg";

const extractClubId = (path) => {
  const match = path.match(/\/club\/([a-zA-Z0-9-]+)(?:\/.*|$)/);
  return match ? match[1] : null;
};

export const FloatingButtonMenu = React.memo(() => {
  const { pathname } = useLocation();
  const clubId = extractClubId(pathname);
  const { permissions } = useClubPermission(clubId);
  const [open, setOpen] = useState(false);
  const [isVisible] = useState(true);

  const [_, dispatch] = useCreateAndSharePostContext();
  const { data } = useGetFeatureToPlatformPermissionQuery();
  const { data: postPermissionsInAnyClub } =
    useGetPostPermissionsInAnyClubQuery();
  const toggleMenu = () => {
    setOpen(!open);
  };

  const handleOpenQueryModal = useCallback(() => {
    setOpen(false);
    dispatch?.({
      type: ActionTypes.OPEN_QUERY_MODAL,
      clubId,
      isAudienceChangeAble: !clubId,
    });
  }, [clubId, dispatch, setOpen]);

  const handleOpenPostModal = useCallback(() => {
    setOpen(false);
    dispatch?.({
      type: ActionTypes.OPEN_POST_MODAL,
      clubId,
      isAudienceChangeAble: !clubId,
    });
  }, [clubId, dispatch, setOpen]);

  const handleOpenStockIdeaModal = useCallback(() => {
    setOpen(false);
    dispatch?.({
      type: ActionTypes.OPEN_STOCK_IDEA_MODAL,
      clubId,
      isAudienceChangeAble: !clubId,
    });
  }, [clubId, dispatch, setOpen]);

  let bottom = 0,
    transitionDelay = 0;
  const menus: Array<ReactNode> = [];

  // PERMISSIONS CHECK
  if (clubId) {
    // Share an Insight
    if (permissions?.includes(ClubPermissionsBasedOnMemberType.CanPost)) {
      bottom = 56;
      transitionDelay = 100;
      menus.push(
        <Zoom
          key="Post"
          in={open}
          style={{ transitionDelay: open ? `${transitionDelay}ms` : "0ms" }}
        >
          <AbsoluteIconButton
            data-title="Start a Discussion"
            sx={{
              bottom,
            }}
            onClick={handleOpenPostModal}
          >
            <ShareAnInsightIcon />
          </AbsoluteIconButton>
        </Zoom>
      );
    }
    // Ask a Query
    if (permissions?.includes(ClubPermissionsBasedOnMemberType.CanQuery)) {
      bottom = bottom + 56;
      transitionDelay = transitionDelay + 100;
      menus.push(
        <Zoom
          key="Ask query"
          in={open}
          style={{ transitionDelay: open ? `${transitionDelay}ms` : "0ms" }}
        >
          <AbsoluteIconButton
            data-title="Ask a Query"
            sx={{
              bottom,
            }}
            onClick={handleOpenQueryModal}
          >
            <AskAQueryIcon />
          </AbsoluteIconButton>
        </Zoom>
      );
    }
    // Share a Stock Idea
    if (
      permissions?.includes(ClubPermissionsBasedOnMemberType.CanCreateStockIdea)
    ) {
      bottom = bottom + 56;
      transitionDelay = transitionDelay + 100;
      menus.push(
        <Zoom
          key="Stock Idea"
          in={open}
          style={{ transitionDelay: open ? `${transitionDelay}ms` : "0ms" }}
        >
          <AbsoluteIconButton
            data-title="Share a Stock Idea"
            sx={{
              bottom,
            }}
            onClick={handleOpenStockIdeaModal}
          >
            <ShareAStockIdeaIcon />
          </AbsoluteIconButton>
        </Zoom>
      );
    }
  } else {
    // Share an Insight
    if (
      data?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanPost
      ) ||
      postPermissionsInAnyClub?.getPostPermissionsInAnyClub?.includes(
        PermissionsInAnyClub?.CanPost
      )
    ) {
      bottom = 56;
      transitionDelay = 100;
      menus.push(
        <Zoom
          key="Post"
          in={open}
          style={{ transitionDelay: open ? `${transitionDelay}ms` : "0ms" }}
        >
          <AbsoluteIconButton
            data-title="Start a Discussion"
            sx={{
              bottom,
            }}
            onClick={handleOpenPostModal}
          >
            <ShareAnInsightIcon />
          </AbsoluteIconButton>
        </Zoom>
      );
    }
    // Ask a Query
    if (
      data?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanQuery
      ) ||
      postPermissionsInAnyClub?.getPostPermissionsInAnyClub?.includes(
        PermissionsInAnyClub?.CanQuery
      )
    ) {
      bottom = bottom + 56;
      transitionDelay = transitionDelay + 100;
      menus.push(
        <Zoom
          key="Ask query"
          in={open}
          style={{ transitionDelay: open ? `${transitionDelay}ms` : "0ms" }}
        >
          <AbsoluteIconButton
            data-title="Ask a Query"
            sx={{
              bottom,
            }}
            onClick={handleOpenQueryModal}
          >
            <AskAQueryIcon />
          </AbsoluteIconButton>
        </Zoom>
      );
    }
    // Share a Stock Idea
    if (
      data?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanCreateStockIdea
      ) ||
      postPermissionsInAnyClub?.getPostPermissionsInAnyClub?.includes(
        PermissionsInAnyClub?.CanCreateStockIdea
      )
    ) {
      bottom = bottom + 56;
      transitionDelay = transitionDelay + 100;
      menus.push(
        <Zoom
          key="Stock Idea"
          in={open}
          style={{ transitionDelay: open ? `${transitionDelay}ms` : "0ms" }}
        >
          <AbsoluteIconButton
            data-title="Share a Stock Idea"
            sx={{
              bottom,
            }}
            onClick={handleOpenStockIdeaModal}
          >
            <ShareAStockIdeaIcon />
          </AbsoluteIconButton>
        </Zoom>
      );
    }
  }

  if (menus.length === 0) return null;
  if (location.pathname.includes("/search")) return null;
  if (location.pathname.includes("/posts")) return null;
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container className={`${isVisible ? "visible" : "hidden"}`}>
        <FabButton
          color="primary"
          onClick={toggleMenu}
          sx={{
            backgroundColor: COLORS.buttonActive,
            color: COLORS.buttonTextActive,
            transition: "transform 0.3s ease-in-out",
            transform: open ? "rotate(45deg)" : "rotate(0deg)",
          }}
          data-tut="reactour__button_to_post"
        >
          <PlusCircle32Icon />
        </FabButton>
        {menus}
      </Container>
    </ClickAwayListener>
  );
});

const FabButton = styled(Fab)`
  width: 48px;
  height: 48px;
  cursor: pointer;
  display: flex;
  &.MuiFab-primary {
    background-color: ${COLORS.buttonActive};
  }
  svg {
    stroke: ${COLORS.buttonActive};
    fill: #ffffff;
  }
`;

const Container = styled("div")({
  position: "fixed",
  bottom: 56,
  right: 8,
  zIndex: 99,
  transition: "transform 0.3s ease, opacity 0.3s ease",
  "&.hidden": {
    transform: "translateY(100px)",
    opacity: 0,
  },
  "&.visible": {
    transform: "translateY(0)",
    opacity: 1,
  },
  "@media (min-width: 960px)": {
    bottom: 20,
  },
});

const AbsoluteIconButton = styled(IconButton)({
  position: "absolute",
  right: 4,
  backgroundColor: "#fff !important",
  border: "4px solid #1b6ac9",
  color: "#1b6ac9 !important",
  "&:after": {
    content: "attr(data-title)",
    position: "absolute",
    right: "calc(100% - 4px)",
    whiteSpace: "nowrap",
    color: "#fff",
    backgroundColor: "#1b6ac9",
    borderRight: "none",
    padding: "8px 16px",
    borderRadius: "16px 0 0 16px",
    fontSize: "14px",
    zIndex: -1,
  },
});
