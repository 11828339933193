import { Link, styled } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";
import { isString } from "lodash-es";
import { Fragment, ReactNode } from "react";

export class MentionedText {
  static getReadableText(
    text: string | Array<string | ReactNode>
  ): ReactNode | Array<ReactNode> {
    if (!text) return <Fragment />;
    if (!Array.isArray(text)) text = [text];
    text = this.changeMentionedLinksToReactNodes(text);
    text = this.changeMentionedUsersToReactNodes(text);
    text = this.changeMentionedHashtagsToReactNodes(text);
    text = this.changeMentionedDollarTagsToReactNodes(text);
    return text;
  }

  private static changeMentionedLinksToReactNodes(
    source: Array<string | ReactNode>
  ): Array<ReactNode> {
    const re = /(https?:\/\/\S+|www\.\S+)/gi;
    return source.flatMap((ele) => {
      if (isString(ele)) {
        const result = ele.split(re);
        const new_result: Array<ReactNode> = [result[0]];
        for (let i = 1, length = result.length; i < length; i += 2) {
          new_result.push(
            <MentionLink
              href={result[i]}
              target="_blank"
              rel="noopener noreferrer"
            >
              {result[i]}
            </MentionLink>,
            result[i + 1]
          );
        }

        return new_result;
      } else {
        return ele;
      }
    });
  }

  private static changeMentionedUsersToReactNodes = (
    source: Array<string | ReactNode>
  ): Array<ReactNode> => {
    const re = /\[@([^:\]]+):([^\]]+)\]/gi;

    return source.flatMap((ele) => {
      if (isString(ele)) {
        const result = ele.split(re);
        const new_result: Array<ReactNode> = [result[0]];

        for (let i = 1, length = result.length; i < length; i += 3) {
          console.log(result[i], result[i + 1], result[i + 2]);
          const username = result[i + 1].split("/").shift();
          const to = `/profile/${encodeURIComponent(username as string)}`;
          new_result.push(
            <MentionUserLink
              to={to}
              replace={window.location.pathname.includes(to)}
            >
              @{result[i]}
            </MentionUserLink>,
            result[i + 2]
          );
        }

        return new_result;
      } else {
        return ele;
      }
    });
  };

  private static changeMentionedHashtagsToReactNodes = (
    source: Array<string | ReactNode>
  ): Array<ReactNode> => {
    const re = /\[#([^:\]]+):([^\]]+)\]/gi;

    return source.flatMap((ele) => {
      if (isString(ele)) {
        const result = ele.split(re);
        const new_result: Array<ReactNode> = [result[0]];

        for (let i = 1, length = result.length; i < length; i += 3) {
          const to = `/hashtag/${encodeURIComponent(result[i])}`;
          new_result.push(
            <MentionHashTagLink
              to={to}
              replace={window.location.pathname.includes(to)}
            >
              #{result[i]}
            </MentionHashTagLink>,
            result[i + 2]
          );
        }

        return new_result;
      } else {
        return ele;
      }
    });
  };

  private static changeMentionedDollarTagsToReactNodes = (
    source: Array<string | ReactNode>
  ): Array<ReactNode> => {
    const re = /\[\$([^:\]]+):([^\]]+)\]/gi;

    return source.flatMap((ele) => {
      if (isString(ele)) {
        const result = ele.split(re);
        const new_result: Array<ReactNode> = [result[0]];

        for (let i = 1, length = result.length; i < length; i += 3) {
          console.log(result[i], result[i + 1], result[i + 2]);
          const paramsLower = result[i + 1].toLocaleLowerCase();
          let to = `/stocktag/${paramsLower}`;
          if (
            paramsLower.includes("stock/") ||
            paramsLower.includes("sector/") ||
            paramsLower.includes("industry/")
          ) {
            to = `/${paramsLower}`;
          }
          new_result.push(
            <MentionStockTagLink
              to={to}
              replace={window.location.pathname.includes(to)}
            >
              ${result[i]}
            </MentionStockTagLink>,
            result[i + 2]
          );
        }

        return new_result;
      } else {
        return ele;
      }
    });
  };
}

const MentionLink = styled(Link)`
  text-decoration: none;
  color: #1b6ac9;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const MentionUserLink = styled(ReactRouterLink)`
  text-decoration: none;
  color: rgb(27, 149, 224);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionHashTagLink = styled(ReactRouterLink)`
  text-decoration: none;
  color: #1b6ac9;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionStockTagLink = styled(ReactRouterLink)`
  text-decoration: none;
  color: rgb(58 105 186);
  font-weight: 600;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
