import React, { FC } from "react";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Link,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { subscriptionsContents } from "./SubscriptionContents";
import { ModalType } from "./SubscriptionModalContext";

interface SubscriptionModalProps extends DialogProps {
  handleCloseModal: VoidFunction;
  modal: ModalType;
}

export const SubscriptionModal: FC<SubscriptionModalProps> = ({
  handleCloseModal,
  modal,
  ...rest
}) => {
  return (
    <Modal onClose={handleCloseModal} {...rest}>
      <ModalHeader>
        <CloseButton onClick={handleCloseModal}>
          <CloseIcon />
        </CloseButton>
      </ModalHeader>
      <Content>{subscriptionsContents(modal)}</Content>
      <Actions>
        <Link href="https://stockedge.com/Plans/stockedgeclub" target="_blank">
          <SubmitButton variant="contained" color="primary">
            Upgrade
          </SubmitButton>
        </Link>
      </Actions>
    </Modal>
  );
};

const Grid = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 25px 0;
`;

const SubmitButton = styled(Button)<ButtonProps>`
  && {
    height: 40px !important;
    width: min(178px, 100%) !important;
    font-size: 0.85rem;
    background-color: #1f4386;
    border-radius: 3px;
    &:hover,
    &:focus {
      background-color: #1f4386;
    }
  }
`;

const Modal = styled(Dialog)`
  && {
    .MuiBackdrop-root {
      background-color: rgba(31, 67, 134, 0.5);
    }

    .MuiPaper-root {
      width: min(500px, 100%);
    }

    @media (min-width: 540px) {
      /* ${Grid} {
      grid-template-columns: repeat(4, 1fr);
      align-items: flex-start;
    } */

      /* ${SubmitButton} {
      min-width: 175px !important;
      width: auto !important;
    } */
    }

    @media (max-width: 540px) {
      /* .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
    .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {
      max-width: 100%;
    } */
    }
  }
`;

const Actions = styled(DialogActions)`
  && {
    justify-content: center;
    padding: 24px 0;
  }
`;

const Content = styled(DialogContent)`
  && {
    padding: 8px 32px;
  }
`;

const ModalHeader = styled(DialogTitle)`
  && {
    justify-content: flex-end;
    display: flex;
    padding: 4px 4px 0;
  }
`;

const CloseButton = styled(IconButton)`
  && {
    padding: 8px;
    color: #5c7082 !important;
  }
`;
