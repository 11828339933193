import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NotificationCountProvider } from "providers";

import FilterPosts from "pages/FilterPosts/FilterPosts";
import BlockedUsers from "pages/settings/blocked-users";

const Home = lazy(() => import("pages/Home"));
const Search = lazy(() => import("pages/Search"));
const Bookmarks = lazy(() => import("pages/Bookmark"));
const Notifications = lazy(() => import("pages/notifications"));
const Hashtag = lazy(() => import("pages/Hashtag/Hashtag"));
const Dollar = lazy(() => import("pages/Dollar"));
const PostDetails = lazy(() => import("pages/PostDetails"));
const SharedPost = lazy(() => import("pages/shared-post"));
const Feedback = lazy(() => import("pages/Feedback"));
const TermsAndConditions = lazy(() => import("pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyAndPolicy"));
const Help = lazy(() => import("pages/Help"));
const PublicAnalyst = lazy(() => import("pages/public-analyst"));
const Ticket = lazy(() => import("pages/Feedback/Ticket"));
const RaiseNewIssue = lazy(() => import("pages/Feedback/RaiseNewIssue"));
const HelpCentre = lazy(() => import("pages/HelpCentre"));
const Learn = lazy(() => import("pages/Learn"));

const OldPostRedirect = lazy(() => import("pages/OldPostRedirect"));
const OldStockRedirect = lazy(() => import("pages/OldStockRedirect"));
const Settings = lazy(() => import("../pages/settings"));
const SettingsAndSupport = lazy(() => import("../pages/SettingsSupport"));
const LandingPage = lazy(() => import("../pages/LandingPage"));
const LogoutOIDC = lazy(() => import("pages/LogoutOIDC"));
const AuthCallback = lazy(() => import("pages/AuthCallback"));
const CompleteProfile = lazy(() => import("pages/CompleteProfile"));
const DownloadLink = lazy(() => import("../pages/DownloadLink"));
const Plans = lazy(() => import("pages/LandingPage/Plans"));
const Clubs = lazy(() => import("pages/clubs"));
const OnboardingPage = lazy(() => import("pages/OnboardingPage"));

const Discover = lazy(() => import("pages/Discover"));
const StockEdgeAnalysts = lazy(
  () => import("pages/Discover/StockEdgeAnalysts")
);
const MarketExperts = lazy(() => import("pages/Discover/MarketExperts"));
const DomainExperts = lazy(() => import("pages/Discover/DomainExperts"));
const EngagingMembers = lazy(() => import("pages/Discover/EngagingMembers"));
const TrendingStocks = lazy(() => import("pages/Discover/TrendingStocks"));
const TrendingSector = lazy(() => import("pages/Discover/TrendingSector"));
const TrendingTopics = lazy(() => import("pages/Discover/TrendingTopics"));
const SavedSearches = lazy(
  () => import("../components/SavedSearchesFullListPage")
);
const ClubCategory = lazy(() => import("pages/ClubCategory"));

// Past Posts
// const ProfileOldPosts = lazy(() => import("pages/profile/Post/OldPosts"));
const TopicOldPosts = lazy(() => import("pages/Hashtag/posts/OldPosts"));
const DollarTagOldPosts = lazy(() => import("pages/Dollar/posts/OldPosts"));

const StockDetailsLayout = lazy(
  () => import("pages/Dollar/StockDetailsLayout")
);

const ClubLayout = lazy(() => import("pages/club/ClubLayout"));
const ClubOldPosts = lazy(() => import("pages/club/OldPosts"));
const ClubAbout = lazy(() => import("pages/club/About"));

const ClubPosts = lazy(() => import("pages/club/ClubPosts"));
const ClubStocks = lazy(() => import("pages/club/Stocks"));
const ClubSectors = lazy(() => import("pages/club/Sectors"));
const ClubTopics = lazy(() => import("pages/club/Topics"));
const ClubContextPosts = lazy(
  () => import("pages/club/components/ClubContextPosts")
);

const WatchlistLayout = lazy(() => import("pages/Watchlist"));

// Profile
const ProfileLayout = lazy(() => import("pages/profile/ProfileLayout"));
const ProfileTabs = lazy(() => import("pages/profile/ProfileTabs"));
const ProfileOldPosts = lazy(() => import("pages/profile/Post/OldPosts"));
const ProfileActivities = lazy(() => import("pages/profile/Activities"));
const ProfilePosts = lazy(() => import("pages/profile/Post"));
const ProfileContextPosts = lazy(
  () => import("pages/profile/components/ProfileContextPosts")
);
const UnderApprovalPosts = lazy(
  () => import("pages/profile/UnderApprovalPosts")
);
const ApprovalPostDetails = lazy(
  () => import("pages/ApprovalPostDetails/ApprovalPostDetails")
);

const Routes1: FC = () => {
  return (
    <NotificationCountProvider>
      <Routes>
        <Route
          path="home"
          element={
            <Suspense>
              <Home />
            </Suspense>
          }
        />

        <Route
          path="watchlist/*"
          element={
            <Suspense>
              <WatchlistLayout />
            </Suspense>
          }
        />

        <Route
          path="search"
          element={
            <Suspense>
              <Search />
            </Suspense>
          }
        />

        <Route
          path="bookmarks"
          element={
            <Suspense>
              <Bookmarks />
            </Suspense>
          }
        />
        {/* Discover routes */}
        <Route
          path="explore"
          element={
            <Suspense>
              <Discover />
            </Suspense>
          }
        />
        <Route
          path="stockedge-analysts"
          element={
            <Suspense>
              <StockEdgeAnalysts />
            </Suspense>
          }
        />
        <Route
          path="market-experts"
          element={
            <Suspense>
              <MarketExperts />
            </Suspense>
          }
        />

        <Route
          path="domain-experts"
          element={
            <Suspense>
              <DomainExperts />
            </Suspense>
          }
        />
        <Route
          path="engaging-members"
          element={
            <Suspense>
              <EngagingMembers />
            </Suspense>
          }
        />

        <Route
          path="saved-searches"
          element={
            <Suspense>
              <SavedSearches />
            </Suspense>
          }
        />
        <Route
          path="trending-stocks"
          element={
            <Suspense>
              <TrendingStocks />
            </Suspense>
          }
        />

        <Route
          path="trending-sectors"
          element={
            <Suspense>
              <TrendingSector />
            </Suspense>
          }
        />

        <Route
          path="trending-topics"
          element={
            <Suspense>
              <TrendingTopics />
            </Suspense>
          }
        />

        <Route
          path="notifications"
          element={
            <Suspense>
              <Notifications />
            </Suspense>
          }
        />

        <Route
          path="learn"
          element={
            <Suspense>
              <Learn />
            </Suspense>
          }
        />

        <Route path="profile">
          <Route
            path=":username/*"
            element={
              <Suspense>
                <ProfileLayout />
              </Suspense>
            }
          >
            <Route
              path="old-posts"
              element={
                <Suspense>
                  <ProfileOldPosts />
                </Suspense>
              }
            />
            <Route
              path="*"
              element={
                <Suspense>
                  <ProfileTabs />
                </Suspense>
              }
            >
              <Route
                path="posts"
                element={
                  <Suspense>
                    <ProfilePosts />
                  </Suspense>
                }
              />
              <Route
                path="under-approval-posts"
                element={
                  <Suspense>
                    <UnderApprovalPosts />
                  </Suspense>
                }
              />

              <Route
                path="activities"
                element={
                  <Suspense>
                    <ProfileActivities />
                  </Suspense>
                }
              />

              <Route path="*" element={<Navigate to="posts" replace />} />
            </Route>

            <Route path={`topics`}>
              <Route path=":hashTagId/*">
                <Route
                  path="posts"
                  element={
                    <Suspense fallback={<></>}>
                      <ProfileContextPosts />
                    </Suspense>
                  }
                />
              </Route>
            </Route>

            <Route path={`stocks`}>
              <Route path=":dollarId/*">
                <Route
                  path="posts"
                  element={
                    <Suspense fallback={<></>}>
                      <ProfileContextPosts />
                    </Suspense>
                  }
                />
              </Route>
            </Route>

            <Route path={`sectors`}>
              <Route path=":dollarId/*">
                <Route
                  path="posts"
                  element={
                    <Suspense fallback={<></>}>
                      <ProfileContextPosts />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>

          <Route path=":username">
            <Route path="under-approval-posts">
              <Route
                path=":approvalRequestId"
                element={
                  <Suspense>
                    <ApprovalPostDetails />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>

        <Route path="club">
          <Route
            path=":clubId/*"
            element={
              <Suspense>
                <ClubLayout />
              </Suspense>
            }
          >
            <Route
              path="old-posts"
              element={
                <Suspense>
                  <ClubOldPosts />
                </Suspense>
              }
            />
            <Route
              path="*"
              element={
                <Suspense>
                  <ClubPosts />
                </Suspense>
              }
            >
              <Route
                path={`posts`}
                element={
                  <Suspense>
                    <ClubPosts />
                  </Suspense>
                }
              />
            </Route>
            {/* <Route
                path={`mobile-club-info`}
                element={
                  <Suspense>
                    <ClubInfoMobileViewPage />
                  </Suspense>
                }
              /> */}
            <Route path={`topics`}>
              <Route
                path="*"
                element={
                  <Suspense>
                    <ClubTopics />
                  </Suspense>
                }
              />
              <Route path=":hashTagId/*">
                <Route
                  path={`posts`}
                  element={
                    <Suspense>
                      <ClubContextPosts />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route path={`stocks`}>
              <Route
                path="*"
                element={
                  <Suspense>
                    <ClubStocks />
                  </Suspense>
                }
              />
              <Route path=":dollarId/*">
                <Route
                  path={`posts`}
                  element={
                    <Suspense>
                      <ClubContextPosts />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route path={`user`}>
              <Route path=":userId/*">
                <Route
                  path={`posts`}
                  element={
                    <Suspense>
                      <ClubContextPosts />
                    </Suspense>
                  }
                />
              </Route>
            </Route>

            <Route path={`sectors`}>
              <Route
                path="*"
                element={
                  <Suspense>
                    <ClubSectors />
                  </Suspense>
                }
              />
              <Route path=":dollarId/*">
                <Route
                  path={`posts`}
                  element={
                    <Suspense>
                      <ClubContextPosts />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route path={`about`} element={<ClubAbout />} />
            <Route path={`*`} element={<Navigate to="posts" replace />} />
          </Route>
        </Route>

        <Route path="category">
          <Route
            path=":clubCategoryId"
            element={
              <Suspense>
                <ClubCategory />
              </Suspense>
            }
          />
        </Route>
        <Route path="hashtag">
          <Route path=":hashtag">
            <Route
              index
              element={
                <Suspense>
                  <Hashtag />
                </Suspense>
              }
            />
            <Route
              path="old-posts"
              element={
                <Suspense>
                  <TopicOldPosts />
                </Suspense>
              }
            />
          </Route>
        </Route>

        <Route path="stock">
          <Route path=":slug">
            <Route
              path=":dollarTagId/*"
              element={
                <Suspense>
                  <StockDetailsLayout />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route path="sector">
          <Route path=":slug">
            <Route path=":dollarTagId/*">
              <Route
                index
                element={
                  <Suspense>
                    <Dollar />
                  </Suspense>
                }
              />
              <Route
                path="old-posts"
                element={
                  <Suspense>
                    <DollarTagOldPosts />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>

        <Route path="industry">
          <Route path=":slug">
            <Route path=":dollarTagId/*">
              <Route
                path="old-posts"
                element={
                  <Suspense>
                    <DollarTagOldPosts />
                  </Suspense>
                }
              />
              <Route
                index
                element={
                  <Suspense>
                    <Dollar />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>

        <Route path="stocktag">
          <Route path=":slug">
            <Route
              path=":securityId"
              element={
                <Suspense>
                  <OldStockRedirect />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route path="posts/*">
          <Route path=":postId/*">
            <Route
              path="*"
              element={
                <Suspense>
                  <PostDetails />
                </Suspense>
              }
            />
          </Route>
        </Route>

        <Route path="old-post">
          <Route
            path=":oldPostId"
            element={
              <Suspense>
                <OldPostRedirect />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="clubs/*"
          element={
            <Suspense>
              <Clubs />
            </Suspense>
          }
        />

        <Route
          path="/locallogout"
          element={
            <Suspense>
              <LogoutOIDC />
            </Suspense>
          }
        />

        <Route
          path="/settings-support"
          element={
            <Suspense>
              <SettingsAndSupport />
            </Suspense>
          }
        />

        <Route
          path="/settings"
          element={
            <Suspense>
              <Settings />
            </Suspense>
          }
        />

        <Route
          path="/help-centre"
          element={
            <Suspense>
              <HelpCentre />
            </Suspense>
          }
        />
        <Route path="feedback">
          <Route
            index
            element={
              <Suspense>
                <Feedback />
              </Suspense>
            }
          />
          <Route
            path="create"
            element={
              <Suspense>
                <RaiseNewIssue />
              </Suspense>
            }
          />
          <Route
            path=":ticketId"
            element={
              <Suspense>
                <Ticket />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/filter-posts"
          element={
            <Suspense>
              <FilterPosts />
            </Suspense>
          }
        />
        <Route path="settings">
          <Route
            path="blocked-users"
            element={
              <Suspense>
                <BlockedUsers />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="onboarding"
          element={
            <Suspense>
              <OnboardingPage />
            </Suspense>
          }
        />
        <Route path="/auth/*">
          <Route
            path="callback"
            element={
              <Suspense>
                <AuthCallback />
              </Suspense>
            }
          />
          <Route
            path="complete-profile"
            element={
              <Suspense>
                <CompleteProfile />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/plans"
          element={
            <Suspense>
              <Plans />
            </Suspense>
          }
        />

        <Route path="analyst">
          <Route
            path=":username"
            element={
              <Suspense>
                <PublicAnalyst />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="/terms"
          element={
            <Suspense>
              <TermsAndConditions />
            </Suspense>
          }
        />

        <Route
          path="/privacy"
          element={
            <Suspense>
              <PrivacyPolicy />
            </Suspense>
          }
        />

        <Route
          path="/migrations"
          element={
            <Suspense>
              <Help />
            </Suspense>
          }
        />

        <Route
          path="/download"
          element={
            <Suspense>
              <DownloadLink />
            </Suspense>
          }
        />

        <Route
          index
          element={
            <Suspense>
              <LandingPage />
            </Suspense>
          }
        />
      </Routes>
    </NotificationCountProvider>
  );
};

export default Routes1;
