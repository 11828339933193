import React, { forwardRef, useCallback } from "react";
import {
  Maybe,
  User,
  useUnblockUserMutation,
  GetBlockedListQuery,
  GetBlockedListDocument,
  GetSavedSearchDocument,
} from "api";
import styled from "@emotion/styled";
import {
  Button,
  CardContent,
  Card,
  CardMedia,
  CardActions,
  Typography,
  CardActionArea,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import BlueTick from "./BlueTick";
import GreenTick from "./GreenTick";
import { useConfirm } from "material-ui-confirm";
import toast from "react-hot-toast";
import YellowTick from "./YellowTick";
import { COLORS } from "assets/colors";
import { UserFollowButton } from "components/Buttons";
import Avatar from "components/Avatar";
import { getIsRingVisible } from "utills";
import {
  UserFollowButtonClickedComponentName,
  UpgradeModalInitialised,
  sendEvent,
  UserButtonClicked,
} from "ga4";

interface TopicCardProps {
  user: Maybe<User> | undefined;
  onClick?: () => void;
}

const UserCard = forwardRef<
  HTMLDivElement,
  TopicCardProps & {
    component_name: UserFollowButtonClickedComponentName;
    upgrade_component_name: UpgradeModalInitialised;
    button_component_name: UserButtonClicked;
  }
>(
  (
    {
      user,
      component_name,
      upgrade_component_name,
      button_component_name,
      onClick,
    },
    ref
  ) => {
    const { user: currentUser } = useAuthContext();

    const isNotMe = !(user?.id === currentUser.id);

    const handleClick = () => {
      sendEvent({
        event: "user_button_clicked",
        component_name: button_component_name,
      });
    };

    return (
      <CustomCard variant="outlined" ref={ref}>
        <CardActionArea
          component={Link}
          to={`/profile/${encodeURIComponent(user?.username as string)}`}
          onClick={handleClick}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            height="80"
            image={user?.cover?.LowResUri}
            alt="User Cover"
          />
          <Avatar
            src={user?.avatar?.LowResUri}
            alt={`${user?.firstName} ${user?.lastName}`}
            hasRing={getIsRingVisible(user?.membershipLevel)}
          />
          <CardContent>
            <Typography variant="h1" textAlign="center">
              {user?.firstName} {user?.lastName}
              {Boolean(user?.isVerified) && <BlueTick />}
              {Boolean(user?.greenTick) && <GreenTick />}
              {Boolean(user?.tick3) && <YellowTick />}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {isNotMe ? (
            <UserFollowButton
              fullWidth
              user={user}
              component_name={component_name}
              upgrade_component_name={upgrade_component_name}
            />
          ) : (
            <Button fullWidth style={{ visibility: "hidden" }}>
              Me
            </Button>
          )}
        </CardActions>
      </CustomCard>
    );
  }
);

export const BlockedUserCard = forwardRef<HTMLDivElement, TopicCardProps>(
  ({ user }, ref) => {
    const confirm = useConfirm();
    const [unblockUser] = useUnblockUserMutation();

    const handleUnblockUser = useCallback(() => {
      confirm({
        title: `Are you sure you want to unblock ${user?.firstName} ${user?.lastName}`,
        confirmationText: "Unblock",
        confirmationButtonProps: {
          style: {
            backgroundColor: COLORS.buttonActive,
            color: "#fff",
          },
        },
        dialogProps: {
          PaperProps: {
            style: {
              maxWidth: 400,
            },
          },
        },
      }).then(() => {
        unblockUser({
          variables: { id: user?.id! },
          update: (cache, { data }) => {
            const old = cache.readQuery<GetBlockedListQuery>({
              query: GetBlockedListDocument,
            });
            cache.writeQuery<GetBlockedListQuery>({
              query: GetBlockedListDocument,
              data: {
                getBlockedList: (old?.getBlockedList || []).filter(
                  (d) => d?.id !== user?.id
                ),
              },
            });
          },
          refetchQueries: [
            {
              query: GetSavedSearchDocument,
            },
          ],
        }).then(() => {
          toast.success("Successfully unblocked");
        });
      });
    }, [user?.id, user, unblockUser]);

    return (
      <CustomCard variant="outlined" ref={ref}>
        <CardMedia
          component="img"
          height="80"
          image={user?.cover?.LowResUri}
          alt="User Cover"
        />
        <Avatar
          src={user?.avatar?.LowResUri}
          alt={`${user?.firstName} ${user?.lastName}`}
          hasRing={getIsRingVisible(user?.membershipLevel)}
        />
        <CardContent>
          <Typography
            variant="subtitle2"
            textAlign="center"
            sx={{
              textDecoration: "none",
              "&:hover": { textDecoration: "none !important" },
            }}
          >
            {user?.firstName} {user?.lastName}
            {Boolean(user?.isVerified) && <BlueTick />}
            {Boolean(user?.greenTick) && <GreenTick />}
            {Boolean(user?.tick3) && <YellowTick />}
          </Typography>
        </CardContent>
        <CardActions>
          <Button fullWidth variant="contained" onClick={handleUnblockUser}>
            Unblock
          </Button>
        </CardActions>
      </CustomCard>
    );
  }
);

export default UserCard;

const CustomCard = styled(Card)`
  max-width: 228px;
  width: 100%;
  .MuiCardContent-root {
    padding: 16px 8px 8px;
  }
  .MuiAvatar-root {
    height: 64px;
    width: 64px;
    margin: -40px auto auto;
  }
  .MuiTypography-h1 {
    text-decoration: none;
    white-space: pre-line;
    line-height: 1.125rem;
    min-height: calc(2 * 1.125rem);
    font-size: 0.875rem;
    &:hover {
      text-decoration: underline;
    }
  }
  .MuiCardActions-root {
    .Mui-disabled.MuiButton-root {
      background-color: #7170701f;
    }
  }
`;

export const UserCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  padding: 8px;
  @media (min-width: 360px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 480px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 540px) {
    padding-left: 0;
    padding-right: 0;
  }
`;
